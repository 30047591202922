<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-tabs v-model="activeName">
      <el-tab-pane label="用户端" name="mini_user_banner"></el-tab-pane>
      <el-tab-pane label="司法端" name="mini_law_banner"></el-tab-pane>
    </el-tabs>
    <div class="container">
      <div class="left">
        <div class="top">
          <img v-if="activeName == 'mini_user_banner'" src="./images/user_top.png" alt="" />
          <img v-if="activeName == 'mini_law_banner'" src="./images/law_top.png" alt="" />
        </div>
        <div :class="['banner', activeName == 'mini_law_banner' ? 'banner_law' : '']">
          <div class="banner-box">
            <!-- 无图片时 -->
            <div v-if="list.length == 0" class="banner_null">
              <i class="el-icon-plus"></i>
            </div>
            <!-- 有图片时 -->
            <div v-else>
              <el-carousel :interval="2000" height="130px" arrow="never">
                <el-carousel-item v-for="(item, i) in list" :key="i">
                  <img :src="item.url" />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="footer">
          <img v-if="activeName == 'mini_user_banner'" src="./images/user_footer.png" alt="" />
          <img v-if="activeName == 'mini_law_banner'" src="./images/law_footer.png" alt="" />
        </div>
      </div>
      <div class="right">
        <el-divider content-position="center">{{ activeName == 'mini_user_banner' ? '用户端banner设置' : '司法端banner设置' }}</el-divider>
        <!-- <el-empty v-if="!show" description="点击左侧虚线区域进行配置"></el-empty> -->
        <el-form ref="form" :model="formData" label-width="80px">
          <div>
            <div class="banner">
              <el-button style="margin-bottom: 10px" type="primary" size="small" icon="el-icon-plus" @click="addBanner">新增一张图片 </el-button>
              <div class="banner-title">
                <span>序号</span>
                <!-- <span>跳转地址</span> -->
                <span>图片</span>
                <span>排序</span>
                <span>操作 </span>
              </div>
              <div class="banner-content" v-for="(banner, i) in list" :key="i">
                <span>{{ i + 1 }}</span>
                <!-- <span>
                  <el-form-item label="" label-width="0">
                    <el-input v-model="banner.go_path" placeholder="请输入" size="small"></el-input>
                  </el-form-item>
                </span> -->
                <span>
                  <FileUpload :url.sync="banner.url"></FileUpload>
                </span>
                <span>
                  <el-form-item label="" label-width="0">
                    <el-input v-model.lazy="banner.sort" placeholder="请输入" size="small"></el-input>
                  </el-form-item>
                </span>
                <span>
                  <el-link style="margin-right: 20px" type="primary" @click="deleteBanner(i)">删除</el-link>
                </span>
              </div>
              <el-empty v-if="list.length == 0" description="暂无图片"></el-empty>
            </div>
          </div>
          <el-form-item label="" label-width="0" style="margin-top: 20px; text-align: center">
            <el-button type="" size="small" @click="getBannerList">取消</el-button>
            <el-button v-checkbtn="isShowBtn(AUTH_BTN.banner_list_add)" type="primary" size="small" @click="save">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getBannerListAPI, updateBannerAPI } from './api'
import FileUpload from '@/components/file-upload/index.vue'
import { nanoid } from 'nanoid'
import { authBtnMixin } from '@/mixins/authBtnMixin'
export default {
  name: 'BannerSetting',
   mixins: [authBtnMixin],
  components: { FileUpload },
  data() {
    return {
      activeName: 'mini_user_banner',
      list: [],
      formData: {}
    }
  },
  computed: {},
  watch: {
    activeName: {
      immediate: true,
      handler(newV, oldV) {
        this.getBannerList()
      }
    }
  },
  mounted() {},

  methods: {
    async getBannerList() {
      this.list = await getBannerListAPI({ type: this.activeName })
    },
    // 添加一张banner
    addBanner() {
      let data = { url: '', sort: '1' }
      this.list.push(data)
    },
    // 删除一张banner
    deleteBanner(i) {
      this.list.splice(i, 1)
    },
    // 提交
    save() {
      let data = {
        type: this.activeName,
        data: this.list
      }
      updateBannerAPI(data).then(() => {
        this.$message.success('保存成功')
        this.getBannerList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    .left {
      margin-right: 40px;
      width: 375px;
      min-height: 600px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      .top {
        img {
          width: 100%;
        }
      }
      .banner {
        background: url('./images/user_banner.png') no-repeat;
        background-size: 100%;
        padding: 0 8px;
        .banner-box {
          cursor: pointer;
          background-color: #fff;
          width: 100%;
          height: 130px;
          box-sizing: border-box;
          border: 2px dashed #409eff;
          border-radius: 10px;
          .banner_null {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #fff;
            i {
              display: block;
              font-size: 30px;
              color: #888;
            }
          }
          img {
            display: block;
            border-radius: 10px;
            width: 100%;
            height: 96%;
          }
        }
      }
      .banner_law {
        background: url('./images/law_banner.png') no-repeat;
      }
      .footer {
        img {
          width: 100%;
        }
      }
      .select-border {
        border: 2px dashed #f56c6c !important;
      }
    }

    .right /deep/ {
      flex: 1;
      min-height: 600px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

      .el-form {
        padding: 0 10px;

        .el-form-item {
          margin-bottom: 0;
        }

        .banner {
          .banner-title {
            background-color: #f4f5f9;
            padding: 10px 20px;
            display: flex;

            & > span {
              padding-right: 10px;
              flex: 2;

              &:nth-last-child(2) {
                flex: 2;
              }

              &:last-child,
              &:first-child {
                flex: 0.6;
              }
            }
          }

          .banner-content {
            display: flex;
            background-color: #fbfbfb;
            padding: 10px 20px;
            border-bottom: 1px solid #e4e5e9;

            & > span {
              padding-right: 10px;
              flex: 2;
              display: flex;
              align-items: center;

              &:nth-last-child(2) {
                flex: 2;
              }

              &:last-child,
              &:first-child {
                flex: 0.6;
              }
            }
          }
        }
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px !important;
        height: 60px !important;
        line-height: 60px !important;
        text-align: center;
      }

      .avatar {
        width: 120px !important;
        height: 60px !important;
        display: block;
      }
    }
  }
}
</style>
